<template>
  <div class="container--fluid pa-0">
    <the-header />
    <div class="container--fluid pa-0">
      <v-card flat color="primary" height="300" class="d-flex">
        <v-card
          flat
          color="secondary"
          height="89"
          class="border-r-0 ma-auto d-flex align-center justify-center px-10 py-4"
        >
          <p
            class="mb-0 line-h-62 font-weight--bold white--text letter-s-25 font-sofia"
            :class="$vuetify.breakpoint.xsOnly ? 'font-30' : 'font-40'"
          >
            Contact Us
          </p>
        </v-card>
      </v-card>
      <v-container :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-71'">
        <v-card flat color="lightGrey" width="863" class="border-r-0 ma-auto">
          <v-form
            :class="$vuetify.breakpoint.xsOnly ? 'px-5 py-6' : 'px-10 py-8'"
            ref="form"
          >
            <p>
              If you would like to contact us, please use the form below and a
              member of our team will be in touch.
            </p>
            <p
              class="mb-6 font-18 line-h-28 font-weight--bold black--text letter-s-25"
            >
              Email Us
            </p>
            <v-row dense class="e-row">
              <v-col cols="12" md="6" class="e-col">
                <v-text-field
                  hide-details="auto"
                  autocomplete="off"
                  flat
                  solo
                  outlined
                  class="form-input border-r-0"
                  placeholder="First Name*"
                  :rules="rules.first_name"
                  v-model="form.first_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="e-col">
                <v-text-field
                  hide-details="auto"
                  autocomplete="off"
                  flat
                  solo
                  outlined
                  class="form-input border-r-0"
                  placeholder="Last Name*"
                  v-model="form.last_name"
                  :rules="rules.last_name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <v-text-field
                  hide-details="auto"
                  autocomplete="off"
                  type="email"
                  flat
                  solo
                  outlined
                  class="form-input border-r-0"
                  placeholder="Email Address*"
                  :rules="rules.email"
                  v-model="form.email"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                      >mdi-email-outline</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="e-row">
              <v-col cols="12" md="6" class="e-col">
                <v-text-field
                  hide-details="auto"
                  autocomplete="off"
                  flat
                  solo
                  outlined
                  class="form-input border-r-0"
                  placeholder="Phone Number*"
                  v-model="form.phone"
                  :rules="rules.phone"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                      >mdil-phone</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="e-col">
                <v-select
                  :items="services"
                  hide-details="auto"
                  autocomplete="off"
                  flat
                  solo
                  outlined
                  class="form-input border-r-0"
                  placeholder="Choose Department"
                  v-model="form.department"
                  :rules="rules.department"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row dense class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <v-textarea
                  hide-details="auto"
                  autocomplete="off"
                  type="email"
                  flat
                  solo
                  outlined
                  class="form-input border-r-0"
                  placeholder="Details of your enquiry"
                  v-model="form.details"
                  :rules="rules.details"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <div class="d-flex justify-end mt-6">
              <v-btn
                :ripple="false"
                color="transparent"
                class="sell-your-ticket-submit height-59 width-166 btn-primary text-capitalize font-20 line-h-20 font-weight--bold letter-s-0 text-color-secondary-100 border-r-0"
                elevation="0"
                @click="submit"
                :loading="loading"
              >
                Submit
                <img
                  class="ml-2 mt-1"
                  src="@/assets/images/right-arrow.svg"
                  alt=""
                />
              </v-btn>
            </div>
          </v-form>
          <div class="px-10" v-if="success">
            <p
              class="py-20 mb-6 bg-color-grey-200 font-18 line-h-25 font-weight--bold text-color-secondary-100 letter-s-25 mb-0 d-flex align-center justify-center"
            >
              Thank you for reaching us. We will get back to you shortly.
            </p>
          </div>
          <div
            class="d-flex align-center px-10"
            :class="
              $vuetify.breakpoint.xsOnly
                ? 'flex-column justify-center pb-6'
                : 'justify-space-between'
            "
          >
            <p
              class="font-18 line-h-28 font-weight--bold letter-s-25 text-color-secondary-100"
            >
              Call Us
            </p>
            <div>
              <span>UK Tel:</span>
              <a
                class="font-16 line-h-14 font-weight--regular text-color-secondary-300"
                href="tel:08712845277"
              >
                0871 284 5277
              </a>
            </div>
          </div>
          <div
            class="d-flex align-center px-10 pb-41"
            :class="
              $vuetify.breakpoint.xsOnly
                ? 'flex-column justify-center'
                : 'justify-space-between'
            "
          >
            <p
              class="font-18 line-h-28 font-weight--bold letter-s-25 text-color-secondary-100"
            >
              Office Address
            </p>
            <p
              class="font-16 line-h-18 font-weight--regular text-color-secondary-300"
            >
              Address Line 1, Address Line 2, Town/City, Country, Post Code
            </p>
          </div>
        </v-card>
      </v-container>
    </div>
    <the-footer />
  </div>
</template>
<script>
import TheFooter from "../components/TheFooter.vue";
import TheHeader from "../components/TheHeader.vue";

export default {
  name: "ConatctUs",
  components: { TheHeader, TheFooter },
  data: () => ({
    services: ["Customer Service", "Delivery Department", "Sales Department"],
    success: false,
    loading: false,
    form: {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      department: null,
      details: null,
    },
    rules: {
      email: [
        (v) => !!v || "Please enter email address",
        (v) =>
          /.+@.+\..+/.test(v) ||
          "Please enter a valid email address e.g. example@example.com",
      ],
      first_name: [(v) => !!v || "Please enter first name"],
      last_name: [(v) => !!v || "Please enter last name"],
      phone: [(v) => !!v || "Please enter phone number"],
      department: [(v) => !!v || "Please choose department"],
      details: [(v) => !!v || "Please fill in details"],
    },
  }),
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("static/CONTACT_US", this.form)
          .then((res) => {
            if (res.result === "success") {
              this.$refs.form.reset();
              this.success = true;
              this.loading = false;
            } else {
              this.loading = false;
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
<style></style>
